import type { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import { useRouter } from 'next/router';
import React, { ReactElement, useState, useEffect } from 'react';
import { encodePassphrase, generateRoomId, randomString } from '../lib/client-utils';
import styles from '../styles/Home.module.css';
import { setCookie, getCookie } from '../lib/cookieUtils';

interface TabsProps {
  children: ReactElement[];
  selectedIndex?: number;
  onTabSelected?: (index: number) => void;
}

function DemoMeetingTab({ label }: { label: string }) {
  const router = useRouter();
  const [e2ee, setE2ee] = useState(false);
  const [stt, setSTT] = useState(false);
  const [chatId, setChatId] = useState('');
  const [sharedPassphrase, setSharedPassphrase] = useState(randomString(64));

  useEffect(() => {
    const storedChatId = getCookie('chatId');

    if (storedChatId) {
      setChatId(storedChatId);
    }
  }, []);

  useEffect(() => {
    setCookie('chatId', chatId, 3650);
  }, [chatId]);
  
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  const handleVideoOpen = () => {
    setIsVideoOpen(true);
  };

  const handleVideoClose = () => {
    setIsVideoOpen(false);
  };

  const startMeeting = async () => {
    const roomId = generateRoomId();
    const passphrase = e2ee ? `#${encodePassphrase(sharedPassphrase)}` : '';
    if (stt && chatId) {
      await fetch('/api/startMeeting', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ roomId, chatId }),
      });
    }
    router.push(`/rooms/${roomId}${passphrase}`);
  };

  return (
    <div className={styles.tabContent}>
      <p style={{ margin: 0 }}>Create Meet for free and unlimit.</p>
      <button style={{ marginTop: '1rem' }} className="lk-button" onClick={startMeeting}>
        Start Meeting
      </button>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
          <input
            id="use-e2ee"
            type="checkbox"
            checked={e2ee}
            onChange={(ev) => setE2ee(ev.target.checked)}
          ></input>
          <label htmlFor="use-e2ee">Enable end-to-end encryption</label>
        </div>
        {e2ee && (
          <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
            <label htmlFor="passphrase">Passphrase</label>
            <input
              id="passphrase"
              type="password"
              value={sharedPassphrase}
              onChange={(ev) => setSharedPassphrase(ev.target.value)}
            />
          </div>
        )}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
          <input
            id="use-stt"
            type="checkbox"
            checked={stt}
            onChange={(ev) => setSTT(ev.target.checked)}
          ></input>
          <label htmlFor="use-stt">Enable speech-to-text</label>
        </div>
        {stt && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
              <a href="https://t.me/meet_summary_bot" target='_blank'>Get cheat id</a>
              <div>
                <span
                  className="askico"
                  onClick={handleVideoOpen}
                >
                  ?
                </span>
                {isVideoOpen && (
                  <div
                    style={{
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      width: '100vw',
                      height: '100vh',
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                    onClick={handleVideoClose}
                  >
                    <video
                      src="/images/chatinfo.mp4"
                      controls
                      autoPlay
                      style={{ maxWidth: '80%', maxHeight: '80%' }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
              <label htmlFor="use-stt-input">Set your telegram chat id</label>
              <input
                id="use-stt-input"
                type="text"
                value={chatId}
                onChange={(ev) => setChatId(ev.target.value)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export const getServerSideProps: GetServerSideProps<{ tabIndex: number }> = async ({
  query,
  res,
}) => {
  res.setHeader('Cache-Control', 'public, max-age=7200');
  const tabIndex = query.tab === 'custom' ? 1 : 0;
  return { props: { tabIndex } };
};


const Home = ({ tabIndex }: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const router = useRouter();
  function onTabSelected(index: number) {
    const tab = index === 1 ? 'custom' : 'demo';
    router.push({ query: { tab } });
  }


  const [logoSrc, setLogoSrc] = useState<string>('/images/uw-logo-meet.webp'); // Default logo


  useEffect(() => {
    const domain = window.location.hostname;

    // Define your domain to logo mapping here
    const domainLogoMap: { [key: string]: string } = {
      'lk.synctum.ru': '/images/aditim-logo.png',
      'meet.xlebmarket.ru': '/images/xm.png',
      'meet.uw-t.com': '/images/uw-logo-meet.webp',
      // Add more mappings as needed
    }

    const newLogoSrc = domainLogoMap[domain] || '/images/aditim-logo.png';
    setLogoSrc(newLogoSrc);

  }, []);

  return (
    <>
      <main className={styles.main} data-lk-theme="default">
        <div className="header">
          <img src="/images/logo-uw-t.com.svg" alt="Meet" width="360" height="45" />
          <img width="" style={{ padding: "20px 0 0 0" }} src={logoSrc} />
          <h2>
            Create and start your own confinement
          </h2>
        </div>
        <DemoMeetingTab label="Create Meet" />
      </main>
      {/* <footer data-lk-theme="default">
        <div className="copirate">Create by <strong><u>Sikach Sergii</u></strong> my contact:</div>
        <div className="lk-button">
          <a href="https://t.me/SergiySV" target="_blank">Telegram</a><br />
          <a href="https://wa.me/+380938729936" target="_blank">Whatsap</a><br />
          <a href="mailto:serjsv87@gmail.com" target="_blank">Mail</a><br />
        </div>
      </footer> */}
    </>
  );
};

export default Home;
